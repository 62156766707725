#cyren-admin.admin-v2 {
  .table :where(th, td) {
    padding-left: 0;
    // @apply align-middle;
    @apply whitespace-nowrap;
  }

  .table.allowWrapping :where(th, td) {
    padding-left: 0;
    // @apply align-middle;
    @apply whitespace-normal;
  }

  .table.allowWrapping :where(th, td).nowrap {
    @apply whitespace-nowrap;
  }

  /* .table :where(thead, tbody) :where(tr:not(:last-child)), */
  /* .table :where(thead, tbody) :where(tr:first-child:last-child) { */
  /*   @apply border-opacity-80; */
  /* } */

  .badge {
    @apply py-4 px-3;
  }

  .sticky-head {
    th,
    td {
      @apply sticky bg-base-300 z-10;
      top: 0;
    }
  }
  .report-table {
    tr:hover td {
      @apply bg-white;
    }
  }
}
