.emr1 {
  display: none;
  @media screen and (max-width: 1440px) {
    display: block;
  }
}
.emr2 {
  display: none;
  @media screen and (min-width: 1440px) and (max-width: 2500px) {
    display: block;
  }
}
.emr3 {
  display: none;
  @media screen and (min-width: 2500px) {
    display: block;
  }
}
