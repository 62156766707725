.svg-path-disable {
  path {
    @apply pointer-events-none;
    // @apply duration-150 ease-in-out;
  }
}

.qs-input-body-select-qs * {
  @apply select-none;
}
