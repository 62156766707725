#root {
  .rtf {
    @apply z-10 m-2 relative bottom-0 left-0 p-0;
    padding: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    &.closed,
    &.open {
      @apply p-0 m-0;
    }
    // TO TEST OPEN STATE
    // .rtf--ab__c {
    &.open .rtf--ab__c {
      &:nth-child(1) {
        transform: translateY(-48px) scale(1);
        transition-delay: 0.03s;
        &.top {
          transform: translateY(48px) scale(1);
        }
      }
      &:nth-child(2) {
        transform: translateY(-48px * 2) scale(1);
        transition-delay: 0.09s;
        &.top {
          transform: translateY(48px * 2) scale(1);
        }
      }
      &:nth-child(3) {
        transform: translateY(-48px * 3) scale(1);
        transition-delay: 0.12s;
        &.top {
          transform: translateY(48px * 3) scale(1);
        }
      }
      &:nth-child(4) {
        transform: translateY(-48px * 4) scale(1);
        transition-delay: 0.15s;
        &.top {
          transform: translateY(48px * 4) scale(1);
        }
      }
      &:nth-child(5) {
        transform: translateY(-48px * 5) scale(1);
        transition-delay: 0.18s;
        &.top {
          transform: translateY(48px * 5) scale(1);
        }
      }
      &:nth-child(6) {
        transform: translateY(-48px * 6) scale(1);
        transition-delay: 0.21s;
        &.top {
          transform: translateY(48px * 6) scale(1);
        }
      }
    }
  }
  .rtf--mb__c {
    @apply p-0 m-0;
  }
  .rtf--mb {
    @apply bg-white border-2 z-10 text-black;
    width: 48px;
    height: 48px;
  }
  .rtf--ab {
    @apply bg-primary;
  }
}
